import React from 'react'

const Whatsapp: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.0537109 24L1.74142 17.8373C0.700401 16.0338 0.152611 13.9878 0.153461 11.8918C0.156091 5.33464 5.49279 0 12.0499 0C15.2323 0.00127 18.2192 1.23986 20.4652 3.48773C22.7113 5.73561 23.9476 8.7236 23.9463 11.9013C23.9437 18.4585 18.6065 23.7938 12.0501 23.7938H12.0452C10.0541 23.793 8.09776 23.2937 6.36018 22.3463L0.0537109 24ZM6.65235 20.1932L7.01342 20.4074C8.53152 21.3081 10.2718 21.7845 12.046 21.7852H12.05C17.4999 21.7852 21.9355 17.351 21.9377 11.9005C21.9388 9.25935 20.9112 6.77584 19.0444 4.90748C17.1776 3.03912 14.6949 2.00959 12.0539 2.00858C6.59989 2.00858 2.16421 6.44251 2.16203 11.8926C2.16127 13.7602 2.68404 15.5792 3.67381 17.153L3.90894 17.527L2.91003 21.1746L6.65235 20.1932Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0767 6.92043C8.83544 6.34098 8.59037 6.41938 8.40791 6.4103C8.23473 6.40168 8.03637 6.39986 7.83821 6.39986C7.64005 6.39986 7.31803 6.47422 7.04555 6.77169C6.77309 7.06918 6.00522 7.7881 6.00522 9.25069C6.00522 10.7133 7.07033 12.1263 7.21896 12.3246C7.36757 12.523 9.31498 15.5244 12.2968 16.8116C13.006 17.1177 13.5597 17.3005 13.9914 17.4375C14.7035 17.6637 15.3515 17.6318 15.8636 17.5553C16.4347 17.47 17.6223 16.8364 17.87 16.1423C18.1177 15.4481 18.1177 14.8532 18.0434 14.7292C17.9691 14.6053 17.7709 14.5309 17.4737 14.3822C17.1764 14.2334 15.715 13.5145 15.4425 13.4154C15.1701 13.3162 14.9719 13.2666 14.7737 13.5641C14.5756 13.8616 14.0059 14.5309 13.8325 14.7292C13.6592 14.9276 13.4858 14.9524 13.1885 14.8036C12.8913 14.6549 11.9335 14.3411 10.7981 13.3287C9.91452 12.5408 9.31791 11.5676 9.14452 11.2702C8.97113 10.9727 9.12608 10.8118 9.27488 10.6637C9.40858 10.5306 9.57211 10.3166 9.72074 10.1431C9.86935 9.96958 9.9189 9.84563 10.018 9.64733C10.1171 9.449 10.0675 9.27547 9.9932 9.12673C9.91888 8.978 9.32442 7.51538 9.0767 6.92043Z"
      />
    </svg>
  )
}

export default Whatsapp
